/**
 * Do not remove any keys!!
 * The shape of this file is to remain the same so that all options are know.
 *
 * If a key does not have a value, set the value to undefined.
 */
module.exports = {
  communityName: "Colony Farms",
  gtagId: undefined,
  logo: {
    src: "https://cdn.maxxpm.com/colonyfarms/logosandbanners/Colony Farms -1.png",
    alt: "Colony Farms Logo",
  },
  nav: {
    bannerSubtitle: undefined,
    bannerTitle: undefined,
    bannerCTA: undefined,
  },
  footer: {
    address: "7495 W Geraint Street",
    cityStateZip: "Magna, UT 84044",
    phone: "(385) 246-1090",
    copyrightYear: "2024",
    logo: "https://cdn.maxxpm.com/colonyfarms/logosandbanners/Colony+Logo+White+50px.png",
  },
  listMarkerSrc: "https://cdn.maxxpm.com/colonyfarms/logosandbanners/colony+farms+tree+4px.png",
  hasCalendlyPopup: false,
  calendlyLink: undefined,
  home: {
    banner: {
      image: "https://cdn.maxxpm.com/colonyfarms/Updated Photos/1.jpg",
      video: undefined,
      title: "Upgraded Townhomes and Apartments",
      subtitle: "We Can't Wait To Welcome You Home!",
    },
    aboutUs: {
      paragraphs: [
        "Colony Farms is the first smart home development of its kind. Offering smart home features such as smart locks, doorbell camera, smart thermostat, door sensors, and a smart home hub control station with many upgradeable features to customize your own smarthome experience. This premier townhome and condo community is located just off HWY 201 and 7200 S in Magna. Colony Farms is minutes away from downtown Salt Lake. Our community offers its residents an array of amenities, including a dog park, a playground, as well as a pool and spacious clubhouse for working out. This beautiful location provides amazing views of the Salt Lake Valley and the Wasatch Mountain Range. Colony Farms is within minutes of shopping and amenities that you can take advantage of. We home you come visit us and see the future of Smart Home Living.",
        "Floor plans include an open kitchen with an array of cabinet space, smoothtop appliances, large dedicated living room, attached garage, laundry hookups, an oversized master bedroom, and other finishing touches you'll be proud to live in.",
      ],
      image: {
        src: "https://cdn.maxxpm.com/colonyfarms/Updated%20Photos/DJI_0391.jpg",
        alt: "Colony Farms Pool",
      },
    },
    floorplanTitle: "Explore Our Floor Plans and Take a 3D Virtual Tour",
    floorplans: [
      {
        hasImage: true,
        image: {
          src: "https://cdn.maxxpm.com/colonyfarms/logosandbanners/Payton.jpg",
          alt: "Colony Farms Payton Floor Plan",
        },
        hasWalkthrough: true,
        walkthrough: {
          src: "https://my.matterport.com/show/?m=H5AC7vTrP4k",
          title: "Payton model 3d walkthrough",
        },
      },
      {
        hasImage: true,
        image: {
          src: "https://cdn.maxxpm.com/colonyfarms/logosandbanners/Delinda.jpg",
          alt: "Colony Farms Delinda Floor Plan",
        },
        hasWalkthrough: true,
        walkthrough: {
          src: "https://my.matterport.com/show/?m=Rpwhu7yof4v",
          title: "Delinda model 3d walkthrough",
        },
      },
      {
        hasImage: true,
        image: {
          src: "https://cdn.maxxpm.com/colonyfarms/logosandbanners/Condos_Unit+A+1+Bed.jpg",
          alt: "Colony Farms 1-Bed Floor Plan A",
        },
        name: "1-Bedroom Unit A",
        hasWalkthrough: false,
      },
      {
        hasImage: true,
        image: {
          src: "https://cdn.maxxpm.com/colonyfarms/logosandbanners/Condos_Unit+B.jpg",
          alt: "Colony Farms 1-Bed Floor Plan B",
        },
        name: "1-Bedroom Unit B",
        hasWalkthrough: false,
      },
      {
        hasImage: true,
        image: {
          src: "https://cdn.maxxpm.com/colonyfarms/logosandbanners/Condos_Unit+C+1+Bed.jpg",
          alt: "Colony Farms 1-Bed Floor Plan C",
        },
        name: "1-Bedroom Unit C",
        hasWalkthrough: false,
      },
      {
        hasImage: true,
        image: {
          src: "https://cdn.maxxpm.com/colonyfarms/logosandbanners/Condos_2+Bed.jpg",
          alt: "Colony Farms 2-Bed Floor Plan",
        },
        name: "2-Bedroom 1-Bath Unit",
        hasWalkthrough: true,
        walkthrough: {
          src: "https://my.matterport.com/show/?m=zbd23uVbqGA",
          title: "2 bed 1 bath 3d walkthrough"
        }
      },
    ],
    midContainerImage: "https://cdn.maxxpm.com/stockphotos/mother+and+children+dancing.jpg",
    imgRow: [
      {
        src: "https://cdn.maxxpm.com/colonyfarms/Updated%20Photos/_DSC0242.jpg",
        alt: "Colony Farms Clubhouse",
      },
      {
        src: "https://cdn.maxxpm.com/colonyfarms/Updated%20Photos/_DSC0646.jpg",
        alt: "Colony Farms Playground",
      },
      {
        src: "https://cdn.maxxpm.com/colonyfarms/Updated%20Photos/DSC_1318-Edit.jpg",
        alt: "Colony Farms Apartments",
      },
    ],
    animalPolicy: {
      img: {
        src: "https://cdn.maxxpm.com/stockphotos/boy+kissing+dog.jpg",
        alt: "Animal policy stock photo",
      },
      policies: [
        "No more than 2 animals are allowed per property",
        "Cats/Dogs: $250/animal*",
        "$50/animal/month for animals under 50lbs*",
        "$75/animal/month for animals 50lbs+*",
        "One-time $75/animal DNA testing fee*",
        "Caged animals: $25/animal/month*",
        "Fish tanks are not permitted",
        "Rabbits and rodents of any kind are not permitted",
      ],
      breedRestrictions: [
        "Pit Bulls & Staffordshire Terriers",
        "Doberman Pinschers",
        "Rottweilers",
        "Chows",
        "Great Danes",
        "Presa Canarios/ Mastiffs",
        "Akitas",
        "Alaskan Malamutes",
        "Huskies",
        "German Shepherds",
        "Shar Peis",
        "Wolf-Hybrids",
      ],
    },
  },
  availability: {
    banner: {
      image: "https://cdn.maxxpm.com/colonyfarms/Updated Photos/DSC_1270-Edit.jpg",
      video: undefined,
      title: "Available Units",
      subtitle:
        "Unit availability is updated often from Monday-Friday during business hours. Evenings and weekends may not reflect accurate availability dates.",
    },
    hasWaitingList: false,
    waitingListJotformId: undefined,
    propertyGroup: "LEADS-CF",
  },
  faq: {
    banner: {
      image: "https://cdn.maxxpm.com/colonyfarms/logosandbanners/family+laying+on+grass.jpg",
    },
    dnaRequired: true,
  },
};
